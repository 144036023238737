import { Box, Button, Center, Flex, Heading, Image, Input } from '@chakra-ui/react'

const SearchBar = () => {
  return (
    <form method='GET' action='/'>
      <Box mt={0} mb={0}>
        <Center>
            <Image src='kurir.png' boxSize={{base: '300px', md: '350px'}} objectFit='contain' />
        </Center>
      </Box>
      <Box mt={0} mb={10}>
        <Center>
          <Heading>Lacak Pesanan Anda</Heading>
        </Center>
      </Box>
      <Flex w='full' maxWidth={'5xl'}>
        <Input
          placeholder="Masukkan Nomor Transaksi"
          variant="outline"
          marginRight='5'
          size='lg'
          flex={1}
          type='text'
          name="resi"
          />

        <Button
          type="submit"
          colorScheme="blue"
          variant="solid"
          size='lg'
          >
          Cari
        </Button>
          
      </Flex>
    </form>
  )
}

export default SearchBar