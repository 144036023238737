import { Alert, AlertIcon, Box, Flex, Text } from "@chakra-ui/react";
import { Step, Steps } from "chakra-ui-steps";
import { useCallback, useEffect, useState } from "react";
import { FiShoppingBag, FiTruck, FiUser } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { defaultOrder, Order } from "../types/order";
import { unstable_batchedUpdates } from "react-dom";
import dayjs from "dayjs";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const ProgressBar = () => {
  const [searchParams] = useSearchParams();
  const resi = searchParams.get("resi") || "";
  const [data, setData] = useState<Order>(defaultOrder);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [status, setStatus] = useState("");

  const tracking = useCallback(async () => {
    const response = await axios.get(API_BASE_URL + "/tracking/" + resi);
    unstable_batchedUpdates(() => {
      setData(response.data.data);

      // preprocess
      // process  == 1
      // assigned  == 2
      // pickup  == 3
      // delivered == 4
      // returned == 4

      switch (response.data.data.status) {
        case "process":
          setActiveStep(1);
          setStatus("Proses pengemasan");
          break;

        case "assigned":
          setActiveStep(2);
          setStatus("Sudah ditangan Kurir");
          break;

        case "pickup":
          setActiveStep(3);
          setStatus(
            response.data.data.isPending === true
              ? "Ditunda"
              : "Sedang diantar Kurir"
          );
          break;

        case "delivered":
          setActiveStep(4);
          setStatus("Diterima oleh penerima");
          break;

        case "returned":
          setActiveStep(4);
          setStatus("Alamat tidak ditemukan");
          break;

        default:
          setActiveStep(0);
      }
    });
  }, [resi]);

  useEffect(() => {
    if (resi !== "") {
      tracking();
    }
  }, [resi, tracking]);

  const steps = [
    { label: "Di Terima", icon: FiShoppingBag },
    { label: "Di Kemas", icon: FiShoppingBag },
    { label: data.isPending === true ? "Di Tunda" : "Di Antar", icon: FiTruck },
    { label: "Selesai", icon: FiUser },
  ];

  return (
    <>
      {data.ID !== "" &&
        data.ID !== "0" &&
        data.status !== "preprocess" &&
        Number(data.ID) !== 0 && (
          <Flex flexDir={{ base: "column", md: "column" }} width={{ base: "full", md: "70%" }}>
            <Steps
              activeStep={activeStep}
              colorScheme={data.isPending === true ? "orange" : "green"}
            >
              {steps.map(({ label, icon }, index) => (
                <Step label={label} key={label} icon={icon}></Step>
              ))}
            </Steps>

            <Flex py={4}>
              <Box
                width="80%"
                w={"full"}
                bg={data.isPending === true ? "orange.200" : "green.100"}
                rounded={"lg"}
                p={6}
                boxShadow={"xs"}
                textAlign={"left"}
              >
                <Text mb={3}>
                  Nomor Transaksi :{" "}
                  <span>
                    <br></br>
                    <b>{data.resi}</b>
                  </span>{" "}
                </Text>
                <Text mb={3}>
                  Tanggal Pesan :{" "}
                  <span>
                    <br></br>
                    <b>{dayjs(data.CreatedAt).format("DD/MM/YYYY")}</b>
                  </span>
                </Text>
                <Text mb={data.isPending === true ? 0 : 3}>
                  Status Pengiriman :
                  <span>
                    <br></br>
                    <b>{status}</b>
                  </span>
                </Text>
                {data.isPending === true && (
                  <Text mb={3}>
                    Alasan  :{" "}
                    <span>
                      
                      <b>{data.isPendingReason}</b>
                    </span>
                  </Text>
                )}
                <Text mb={3}>
                  Nama Pemesan :{" "}
                  <span>
                    <br></br>
                    <b>{data.name}</b>
                  </span>
                </Text>
                <Text mb={3}>
                  Kontak :{" "}
                  <span>
                    <br></br>
                    <b>{"********" + (data.phone?.slice(8) ?? "")}</b>
                  </span>
                </Text>
                <Text mb={3}>
                  Pengiriman :{" "}
                  <span>
                    <br></br>
                    <b>{data.productName}</b>
                  </span>
                </Text>
                {(data.status === "pickup" ||
                  data.status === "delivered" ||
                  data.status === "returned") && (
                  <Text mb={3}>
                    {" "}
                    Kurir :
                    <span>
                      <br></br>
                      <b>{data.courier}</b>
                    </span>
                  </Text>
                )}
              </Box>
            </Flex>
          </Flex>
        )}

      {
        data.status === "preprocess" && (
          <Box>
            <Alert status="success">
              <AlertIcon />
              Pesanan sudah diterima dan sedang diproses.
            </Alert>
          </Box>
        )

        // <p>Pesanan sudah diterima dan sedang diproses.</p>
      }
    </>
  );
};

export default ProgressBar;
