import * as React from "react"
import {
  ChakraProvider,
  Box,
  VStack,
  Grid,
} from "@chakra-ui/react"
import SearchBar from "./components/SearchBar"
import { extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import ProgressBar from "./components/ProgressBar";



const theme = extendTheme({
  components: {
    Steps,
  },
});

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <VStack spacing={8}>
          <SearchBar />
          
          <ProgressBar />
        </VStack>
      </Grid>
    </Box>
  </ChakraProvider>
)
