export interface Order {
  status?: string;
  ID?: string;
  CreatedAt?: string;
  name?: string;
  courier: string;
  phone?: string;
  refNo?: string;
  productName?: string;
  resi?: string;
  isPending ?: boolean;
  isPendingReason ?: string;
}

export const defaultOrder: Order = {
  status: '',
  ID: '',
  name: '',
  courier: '',
  phone: '',
  refNo: '',
  productName: '',
  resi: '',
  isPending: false,
  isPendingReason: '',
}